import Route from "route-parser";

export type RouteDef = {
  route: string;
  create: (params?: Record<string, string>) => string;
};

function createRouterDefinition(
  parent: RouteDef | null,
  path: string,
  queryNames?: string[]
): RouteDef {
  const query = queryNames
    ? `(?${queryNames.map((n) => `${n}=:${n}`).join("&")})`
    : "";
  const fullyQualitifiedPath = `${path}${query}`;
  const route = new Route(fullyQualitifiedPath);
  return {
    route: `${parent ? parent.route : ""}${path}`,
    create: (params?: Record<string, string>) => {
      const parentPath = parent ? parent.create(params) : "";
      const childPath = route.reverse(params ?? {});
      if (!childPath) {
        throw new Error(
          `Could not create path for "${fullyQualitifiedPath}" params=${JSON.stringify(
            params
          )}`
        );
      }
      return `${parentPath}${childPath}`;
    },
  };
}

const ROUTES = {
  ROOT: createRouterDefinition(null, "/"),
  LANGUAGES: {
    ...createRouterDefinition(null, "/languages"),
    LANGUAGE: {
      ...createRouterDefinition(null, "/languages/:code"),
      DICTIONARY: {
        ...createRouterDefinition(null, "/languages/:code/dictionary", [
          "search",
        ]),
        WORD_VIEW: createRouterDefinition(
          null,
          "/languages/:code/dictionary/words/:id/view"
        ),
      },
    },
  },
};

export default ROUTES;
