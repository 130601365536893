import { ApiResponse, WordModel } from "@conworkshop/api-js";
import { useQuery, UseQueryResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type WordRetrieveResponse = ApiResponse<WordModel>;

export type ApiWordsGetParams = {
  id: string;
  code: string;
};

function useApiRetrieveDictionaryWord(
  params: ApiWordsGetParams
): UseQueryResult<WordRetrieveResponse> {
  const apiClient = useApiClient();
  const { code, id } = params;
  return useQuery(["word", params], async () => {
    return apiClient.retrieveDictionaryWord(code, id);
  });
}

export default useApiRetrieveDictionaryWord;
