import { ApiResponse, LanguageReference } from "@conworkshop/api-js";
import { useQuery, UseQueryResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type UserLanguageListResponse = ApiResponse<LanguageReference[]>;

function useApiListCurrentUserLanguages(): UseQueryResult<UserLanguageListResponse> {
  const apiClient = useApiClient();
  return useQuery(["user.languages"], async () => {
    return apiClient.listCurrentUserLanguages();
  });
}

export default useApiListCurrentUserLanguages;
