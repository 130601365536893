import React from "react";
import { Route, Switch } from "react-router-dom";

import ROUTES from "../../router";
import LanguageRouter from "./Language/LanguageRouter";
import LanguagesHomeScreen from "./LanguagesHomeScreen";

function LanguagesRouter() {
  return (
    <Switch>
      <Route path={ROUTES.LANGUAGES.LANGUAGE.route}>
        <LanguageRouter />
      </Route>
      <Route path={ROUTES.LANGUAGES.route}>
        <LanguagesHomeScreen />
      </Route>
    </Switch>
  );
}

export default LanguagesRouter;
