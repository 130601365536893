export function strTruncate(
  value: string | undefined | null,
  length: number
): string {
  if (!value) {
    return "";
  }
  if (value.length > 5) {
    return value.substring(0, length) + "…";
  }
  return value;
}

export function strCapitalize(value: string | undefined | null): string {
  if (!value) {
    return "";
  }
  return value.replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase());
}
