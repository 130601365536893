import { ApiResponse, LanguageModel } from "@conworkshop/api-js";
import { useQuery, UseQueryResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type ApiWordsListParams = {
  code: string;
};

export type GetLanguageConfigResponse = ApiResponse<LanguageModel>;

function useApiRetrieveLanguage(
  params: ApiWordsListParams
): UseQueryResult<GetLanguageConfigResponse> {
  const apiClient = useApiClient();
  const { code } = params;
  return useQuery(["languages", { params }], async () => {
    return apiClient.retrieveLanguage(code);
  });
}

export default useApiRetrieveLanguage;
