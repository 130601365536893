import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";

import H1 from "../Typography/H1";
import H2 from "../Typography/H2";

export type PanelProps = {
  title?: string;
  subTitle?: string;
  elevated?: boolean;
  upperRight?: React.ReactElement;
  children: React.ReactElement;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 12,
    paddingBottom: 24,
  },
  title: {
    marginBottom: 12,
  },
  contentNoHeader: {
    marginTop: 0,
  },
  contentHeader: {
    marginTop: 24,
  },
}));

function Panel(props: PanelProps) {
  const { title, subTitle, children, elevated, upperRight } = props;
  const classes = useStyles(props);
  const hasHeader = title || subTitle || upperRight ? true : false;
  return (
    <Paper elevation={elevated ? 3 : 0}>
      <Container className={classes.container}>
        <div>
          <div style={{ float: "left" }}>
            {title ? <H1>{title}</H1> : undefined}
            {subTitle ? (
              <H2 fontColor="grey" fontWeight="normal">
                {subTitle}
              </H2>
            ) : undefined}
          </div>
          <div style={{ maxWidth: '40%', float: "right" }}>{upperRight}</div>
        </div>
        <div
          className={
            hasHeader ? classes.contentHeader : classes.contentNoHeader
          }
        >
          {children}
        </div>
      </Container>
    </Paper>
  );
}

export default Panel;
