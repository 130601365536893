import { ApiCursorResponse, WordlinkModel } from "@conworkshop/api-js";
import { useQuery, UseQueryResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type ApiWordlinksQueryParams = {
  search?: string;
  page: number;
  limit: number;
};

export type GetWorklinksResponse = ApiCursorResponse<WordlinkModel[]>;

function useApiQueryWordlinks(
  params: ApiWordlinksQueryParams
): UseQueryResult<GetWorklinksResponse> {
  const apiClient = useApiClient();
  const { search, page, limit } = params;
  return useQuery(
    ["wordlinks.query", { params }],
    async () => {
      return apiClient.queryWordlinks(search ?? '', { page, limit });
    },
    { keepPreviousData: true, enabled: !!search && search.trim() !== "" }
  );
}

export default useApiQueryWordlinks;
