import { useEffect } from "react";
import { UseQueryResult } from "react-query";

function useQuerySuccess<T>(
  query: UseQueryResult<T>,
  handler: (data: T) => void
) {
  const { isFetched, isError, data } = query;
  useEffect(() => {
    if (isFetched && !isError && data) {
      handler(data);
    }
  }, [handler, isFetched, isError, data]);
}

export default useQuerySuccess;
