import { ApiCursor, WordFacet, WordModel } from "@conworkshop/api-js";
import {
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import React, { createContext, useContext, useEffect, useState } from "react";

import { createNewWordModel, isNewWord } from "../../../../Api/types";
import useApiCreateDictionaryWord from "../../../../Api/useApiCreateDictionaryWord";
import useApiDeleteDictionaryWord from "../../../../Api/useApiDeleteDictionaryWord";
import useApiQueryDictionaryWords from "../../../../Api/useApiQueryDictionaryWords";
import useApiUpdateDictionaryWord from "../../../../Api/useApiUpdateDictionaryWord";
import useQuerySuccess from "../../../../Components/QueryHandling/useQuerySuccess";
import { useLanguageContext } from "../../../../Contexts/LanguageContext";
import useIsNotMobile from "../../../../Hooks/useIsNotMobile";
import {
  DictionaryColumnName,
  DictionaryQuery,
  DictionarySettings,
} from "./types";
import { useDictionaryLocalStorage } from "./useDictionaryLocalStorage";

export type DictionaryContextState = {
  code: string;
  rows: WordModel[];
  rowModes: GridRowModesModel;
  query: DictionaryQuery;
  cursor?: ApiCursor;
  settings: DictionarySettings;
  availableWordFacets: WordFacet[];
  isLanguageEditable: boolean;
  addWord: () => void;
  saveWord: (word: WordModel) => Promise<WordModel>;
  transitionStateEditWord: (id: GridRowId) => void;
  transitionStateViewWord: (id: GridRowId, isCancel: boolean) => void;
  removeWord: (id: GridRowId) => void;
  updateDictionaryQuery: (query: DictionaryQuery) => void;
  updateColumnWidth: (name: DictionaryColumnName, width: number) => void;
  updateColumnOrder: (order: DictionaryColumnName[]) => void;
};

export type DictionaryContextProviderProps = {
  children: (() => React.ReactNode) | React.ReactNode;
};

const DictionaryContext =
  createContext<DictionaryContextState | undefined>(undefined);

function DictionaryContextProvider({
  children,
}: DictionaryContextProviderProps) {
  const { language, isLanguageEditable, facets } = useLanguageContext();
  const { code } = language;
  const {
    readLocalStorageDictionarySettings,
    readLocalStorageDictionaryQuery,
    saveLocalStorageDictionarySettings,
    saveLocalStorageDictionaryQuery,
  } = useDictionaryLocalStorage();
  const isNotMobile = useIsNotMobile();
  const localStorageQuery = readLocalStorageDictionaryQuery(code);
  const localStorageSettings = readLocalStorageDictionarySettings();
  const [query, setQuery] = useState(localStorageQuery);
  const [settings, setSettings] = useState(localStorageSettings);
  const [cursor, setCursor] = useState<ApiCursor | undefined>();
  const [rowsQueried, setRowsQueried] = useState<WordModel[]>([]);
  const [rows, setRows] = React.useState<WordModel[]>([]);
  const [rowsAdded, setRowsAdded] = React.useState<WordModel[]>([]);
  const [rowsEdited, setRowsEdited] = React.useState<Record<string, WordModel>>(
    {}
  );
  const [rowModes, setRowModes] = React.useState<GridRowModesModel>({});

  // Effects
  useEffect(() => {
    saveLocalStorageDictionaryQuery(code, query);
  }, [code, query, saveLocalStorageDictionaryQuery]);

  useEffect(() => {
    saveLocalStorageDictionarySettings(settings);
  }, [settings, saveLocalStorageDictionarySettings]);

  // ConWorkshop REST APIs
  const apiQuery = useApiQueryDictionaryWords({
    code,
    search: query.search,
    page: query.pageNumber,
    filterValue: query.filterValue,
    limit: query.pageSize,
    sortFacet: query.sortFacet,
    sortDir: query.sortDir,
  });

  useQuerySuccess(apiQuery, (res) => {
    const { data, cursor } = res;
    setRowsQueried(data);
    setCursor(cursor);
  });

  const apiWordUpdate = useApiUpdateDictionaryWord();
  const apiWordCreate = useApiCreateDictionaryWord();
  const apiWordDelete = useApiDeleteDictionaryWord();

  useEffect(() => {
    const rows = rowsQueried.map((r) => rowsEdited[r.id] ?? r);
    setRows([...rowsAdded, ...rows]);
  }, [rowsAdded, rowsEdited, rowsQueried, setRows]);

  // Dictionary Context APIs
  const addWord = () => {
    const row = { ...createNewWordModel(language) };
    setRowsAdded([row, ...rowsAdded]);
    transitionStateEditWord(row.id);
  };

  const saveWord = (word: WordModel): Promise<WordModel> => {
    return new Promise((resolve, reject) => {
      const { id } = word;
      if (isNewWord(id)) {
        return apiWordCreate.mutate(word, {
          onSuccess: (res) => {
            const { data } = res;
            const updatedRowsNew = rowsAdded.map((row) =>
              row.id === id ? data : row
            );
            setRowsAdded(updatedRowsNew);
            resolve(data);
          },
          onError: (error) => {
            transitionStateEditWord(id);
            reject(error);
          },
        });
      }
      return apiWordUpdate.mutate(
        {
          code: word.language.code,
          word,
        },
        {
          onSuccess: (res) => {
            const { data } = res;
            setRowsEdited({ ...rowsEdited, [data.id]: data });
            resolve(data);
          },
          onError: (error) => {
            transitionStateEditWord(id);
            reject(error);
          },
        }
      );
    });
  };

  const removeWord = (id: GridRowId) => {
    apiWordDelete.mutate(
      { code, id: id as string },
      {
        onSuccess: () => {
          setRows(rows.filter((row) => row.id !== id));
          setRowsAdded(rows.filter((row) => row.id !== id));
        },
        onError: () => {
          // TODO Error notification
        },
      }
    );
  };

  const updateDictionaryQuery = (query: DictionaryQuery) => {
    setRowsAdded(rows.filter((row) => isNewWord(row.id)));
    setQuery(query);
  };

  const updateColumnWidth = (name: DictionaryColumnName, width: number) => {
    setSettings({
      ...settings,
      columnWidths: { ...settings.columnWidths, [name]: width },
    });
  };

  const updateColumnOrder = (order: DictionaryColumnName[]) => {
    setSettings({
      ...settings,
      columnOrder: order,
    });
  };

  const transitionStateEditWord = (id: GridRowId) => {
    setRowModes({
      ...rowModes,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "word" },
    });
  };

  const transitionStateViewWord = (id: GridRowId, isCancel: boolean) => {
    if (isNewWord(id as string) && isCancel) {
      setRowsAdded(rowsAdded.filter((row) => row.id !== id));
      return;
    }
    setRowModes({
      ...rowModes,
      [id]: { mode: GridRowModes.View, ignoreModifications: isCancel },
    });
  };

  const state = {
    code,
    query,
    cursor,
    rows,
    rowModes,
    settings,
    isLanguageEditable: isLanguageEditable && isNotMobile,
    availableWordFacets: [...facets, WordFacet.CREATED_AT],
    addWord,
    removeWord,
    saveWord,
    transitionStateEditWord,
    transitionStateViewWord,
    updateDictionaryQuery,
    updateColumnWidth,
    updateColumnOrder,
  };

  return (
    <DictionaryContext.Provider value={state}>
      {typeof children === "function" ? children() : children}
    </DictionaryContext.Provider>
  );
}

function useDictionaryContext(): DictionaryContextState {
  const context = useContext(DictionaryContext);
  if (!context) {
    throw new Error("DictionaryContext is empty.");
  }
  return context;
}

export { useDictionaryContext };

export default DictionaryContextProvider;
