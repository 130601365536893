import { WordRegister } from "@conworkshop/api-js";
import React from "react";

import { REGISTER_NEUTRAL } from "../../Api/data/registers";
import Chip from "../Data/Chip";
import { ComponentSize } from "../styles";

export type RegisterViewProps = {
  variation?: "abbrev" | "full";
  size?: ComponentSize;
  value?: WordRegister;
};

function RegisterView(props: RegisterViewProps) {
  const { value, size, variation } = props;

  const register = value ?? REGISTER_NEUTRAL;

  return (
    <Chip
      variant="outlined"
      size={size}
      label={variation === "abbrev" ? register.code : register.label}
    />
  );
}

export default RegisterView;
