import {
  LanguageModel,
  LanguageReference,
  ModelType,
  WordModel,
} from "@conworkshop/api-js";
import { nanoid } from "nanoid";

export function createNewWordModel(language: LanguageReference): WordModel {
  return {
    id: `temp-${nanoid()}`,
    type: ModelType.WORD,
    language,
    classes: [],
    word: "",
    alternateWord: "",
    ipa: "",
    xsampa: "",
    sample: "",
    etymology: "",
    notes: "",
  } as any as WordModel;
}

export function isNewWord(id: string): boolean {
  return id.startsWith("temp-");
}

export function toLanguageReference(
  language: LanguageModel
): LanguageReference {
  const { code, name, flagRegular, flagSmall } = language;
  return {
    code,
    type: ModelType.LANGUAGE,
    name,
    flagSmall,
    flagRegular,
  };
}
