import { WordClassReference } from "@conworkshop/api-js";
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-pro";

import WordClassesEdit from "../../../../Components/Widgets/WordClassesEdit";
import { useLanguageContext } from "../../../../Contexts/LanguageContext";

function WordClassesDataTableEditControl(props: GridRenderEditCellParams) {
  const { id, value, field, error } = props;
  const apiRef = useGridApiContext();

  const { classes: wordClasses } = useLanguageContext();

  const onChange = (newValue: WordClassReference[]) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <WordClassesEdit
      value={value}
      wordClasses={wordClasses}
      onChange={onChange}
      error={error}
    />
  );
}

export default WordClassesDataTableEditControl;
