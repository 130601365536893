import { WordPartOfSpeech } from "@conworkshop/api-js";
import React from "react";

import { strCapitalize } from "../../Util/string_fns";
import Chip from "../Data/Chip";
import { ComponentSize } from "../styles";
import Text from "../Typography/Text";

export type PartOfSpeechProps = {
  format?: "text" | "chip";
  variation?: "abbrev" | "full";
  size?: ComponentSize;
  value?: WordPartOfSpeech;
};

function PartOfSpeechView(props: PartOfSpeechProps) {
  const { value, size, format, variation } = props;

  if (format === "chip" && value) {
    return (
      <Chip
        size={size}
        color="primary"
        label={variation === "abbrev" ? value.code : strCapitalize(value.label)}
      />
    );
  }

  return (
    <Text size={size}>
      {!value ? "" : variation === "abbrev" ? value.code : value.label}
    </Text>
  );
}

export default PartOfSpeechView;
