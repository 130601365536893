import { WordRegister } from "@conworkshop/api-js";

export const REGISTER_DEFAULT = {
  code: "",
  label: "Neutral",
};

export const REGISTER_NEUTRAL = {
  code: "N",
  label: "Neutral",
};

export const REGISTER_AFFECTIONATE = {
  code: "AFFC",
  label: "Affectionate/Endearing",
};

export const REGISTER_ARCHAIC = {
  code: "ARCH",
  label: "Archaic",
};

export const REGISTER_CASUAL = {
  code: "CASL",
  label: "Casual",
};

export const REGISTER_DATED = {
  code: "DATD",
  label: "Dated",
};

export const REGISTER_EUPHEMISTIC = {
  code: "EUPH",
  label: "Euphemistic",
};

export const REGISTER_FORMAL = {
  code: "FORM",
  label: "Formal",
};

export const REGISTER_HONOURIFIC = {
  code: "HONR",
  label: "Honourific",
};

export const REGISTER_HUMBLE = {
  code: "HUMB",
  label: "Humble",
};

export const REGISTER_HUMOROUS = {
  code: "HMRS",
  label: "Humorous/Sarcastic",
};

export const REGISTER_INTIMATE = {
  code: "INTM",
  label: "Intimate",
};

export const REGISTER_JARGON = {
  code: "JRGN",
  label: "Jargon",
};

export const REGISTER_POETIC = {
  code: "POET",
  label: "Poetic",
};

export const REGISTER_RELIGIOUS = {
  code: "RELG",
  label: "Religious/Pious",
};

export const REGISTER_SLANG = {
  code: "SLNG",
  label: "Slang",
};

export const REGISTER_TABOO = {
  code: "TBOO",
  label: "Taboo",
};

export const REGISTER_VULGAR = {
  code: "VULG",
  label: "Vulgar",
};

export const REGISTER_OTHER = {
  code: "OTHR",
  label: "Other (see notes)",
};

export const REGISTER_LIST: WordRegister[] = [
  REGISTER_NEUTRAL,
  REGISTER_AFFECTIONATE,
  REGISTER_ARCHAIC,
  REGISTER_CASUAL,
  REGISTER_DATED,
  REGISTER_EUPHEMISTIC,
  REGISTER_FORMAL,
  REGISTER_HONOURIFIC,
  REGISTER_HUMBLE,
  REGISTER_HUMOROUS,
  REGISTER_INTIMATE,
  REGISTER_JARGON,
  REGISTER_POETIC,
  REGISTER_RELIGIOUS,
  REGISTER_SLANG,
  REGISTER_TABOO,
  REGISTER_VULGAR,
  REGISTER_OTHER,
].sort((a, b) => a.label.localeCompare(b.label));

export function getRegister(
  code: string | undefined,
  isRequired = false
): WordRegister | undefined {
  const register = REGISTER_LIST.find((p) => p.code === code);
  if (!register && isRequired) {
    throw new Error("Unexpected value for Register.");
  }
  return register;
}
