import { WordClassModel } from "@conworkshop/api-js";
import React from "react";

import Chip from "../Data/Chip";
import { ComponentSize } from "../styles";

export type WordClassesViewProps = {
  size?: ComponentSize;
  variation?: "abbrev" | "full";
  value?: WordClassModel[];
};

function WordClassesView(props: WordClassesViewProps) {
  const { value, variation, size } = props;

  const sortedValues = value ? [...value] : [];
  sortedValues.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <React.Fragment>
      {sortedValues.map((wordClass) => (
        <Chip
          key={wordClass.code}
          size={size}
          label={
            variation === "abbrev"
              ? wordClass.code
              : `[${wordClass.partOfSpeech.code}] ${wordClass.label}`
          }
        />
      ))}
    </React.Fragment>
  );
}

export default WordClassesView;
