import { WordFacet } from "@conworkshop/api-js";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import React from "react";

import { ComponentWidth, toWidthProperty } from "../../../../Components/styles";

export type WordFacetSelectorProps = {
  width: ComponentWidth;
  value: WordFacet[];
  facets: WordFacet[];
  onChange: (value: WordFacet[]) => void;
};

const FACET_NAMES: Record<WordFacet, string> = {
  [WordFacet.ALTERNATE_WORD]: "Alternate Word",
  [WordFacet.CLASSES]: "Classes",
  [WordFacet.CREATED_AT]: "Added At",
  [WordFacet.DIALECT]: "Dialect",
  [WordFacet.ETYMOLOGY]: "Etymology",
  [WordFacet.IMAGE_URL]: "Image URL",
  [WordFacet.IPA]: "IPA",
  [WordFacet.NOTES]: "Notes",
  [WordFacet.REGISTER]: "Register",
  [WordFacet.SAMPLE]: "Sample",
  [WordFacet.SOURCE_LANGUAGE]: "Source Language",
  [WordFacet.XSAMPA]: "X-Sampa",
};

const useStyles = makeStyles({
  root: (props: WordFacetSelectorProps) => ({
    width: toWidthProperty(props, 300),
  }),
  input: (props: WordFacetSelectorProps) => ({
    width: toWidthProperty(props, 300),
  }),
});

type Option = {
  code: WordFacet;
  label: string;
};

function WordFacetSelector(props: WordFacetSelectorProps) {
  const { value, facets, onChange } = props;
  const classes = useStyles(props);

  const options: Option[] = facets.map((v) => ({
    code: v,
    label: FACET_NAMES[v],
  }));
  options.sort((a, b) => a.label.localeCompare(b.label));

  const selected = value
    .filter((v) => facets.indexOf(v) > -1)
    .map((v) => ({
      code: v,
      label: FACET_NAMES[v],
    }));

  return (
    <Autocomplete
      multiple
      className={classes.root}
      value={selected}
      options={options}
      limitTags={3}
      size="small"
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => {
        return option.code === value.code;
      }}
      filterSelectedOptions
      disableClearable
      onChange={(event, newValue: Option[]) => {
        onChange(newValue.map((v) => v.code));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.input}
          label="Additional Fields"
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.code}>
            {option.label}
          </li>
        );
      }}
    />
  );
}

export default WordFacetSelector;
