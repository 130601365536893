import { LanguageReference } from "@conworkshop/api-js";
import React, { createContext, useContext, useState } from "react";
import { useParams } from "react-router";

import useApiListCurrentUserLanguages from "../Api/useApiListCurrentUserLanguages";
import QueryResultRenderer from "../Components/QueryHandling/QueryResultRenderer";
import useQuerySuccess from "../Components/QueryHandling/useQuerySuccess";

export type LanguageCode = string | null;

export type LanguageName = string | null;

export type UserLanguageContextState = {
  languages: LanguageReference[];
};

export type UserLanguageContextProviderProps = {
  children: (() => React.ReactNode) | React.ReactNode;
};

const UserLanguageContext =
  createContext<UserLanguageContextState | undefined>(undefined);

function UserLanguageContextProvider({
  children,
}: UserLanguageContextProviderProps) {
  const query = useApiListCurrentUserLanguages();
  const [isStateReady, setIsStateReady] = useState(false);
  const [languages, setLanguages] = useState<LanguageReference[]>([]);

  useQuerySuccess(query, (languages) => {
    setLanguages(languages.data);
    setIsStateReady(true);
  });

  const state = { languages };

  return (
    <QueryResultRenderer query={query} isStateReady={isStateReady}>
      {() => (
        <UserLanguageContext.Provider value={state}>
          {typeof children === "function" ? children() : children}
        </UserLanguageContext.Provider>
      )}
    </QueryResultRenderer>
  );
}

function useUserLanguageContext(): UserLanguageContextState {
  const context = useContext(UserLanguageContext);
  if (!context) {
    throw new Error("UserLanguageContext is empty");
  }
  return context;
}

function useLanguage(): LanguageReference | undefined {
  const { code } = useParams<{ code?: string }>();
  const { languages } = useUserLanguageContext();
  if (code) {
    const language = languages.find((l) => l.code === code);
    if (language) {
      return language;
    }
  }
}

function useLanguageRequired(): LanguageReference {
  const language = useLanguage();
  if (language) {
    return language;
  }
  throw new Error("Language not defined.");
}

export {
  useLanguage,
  useLanguageRequired,
  UserLanguageContextProvider,
  useUserLanguageContext,
};
