import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

import logo from "./cws-logo.svg";
import UserMenu from "./UserMenu";

interface HeaderProps {
  title: string;
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#00af3a",
  },
}));

function Header(props: HeaderProps) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar className={classes.root} position="sticky">
        <Toolbar>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            noWrap
            sx={{ flex: 1 }}
          >
            <img src={logo} height={45} alt="ConWorkShop Logo" />
          </Typography>
          <UserMenu />
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;
