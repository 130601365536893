import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import ApiContextProvider from "./Api/ApiContext";
import Header from "./Components/Global/Header";
import CONFIG from "./config";
import { UserContextProvider } from "./Contexts/UserContext";
import { UserLanguageContextProvider } from "./Contexts/UserLanguageContext";
import ROUTES from "./router";
import LanguagesHomeRouter from "./Screens/Languages/LanguagesHomeRouter";
import THEME from "./theme";

const queryClient = new QueryClient();

const useStyles = makeStyles({
  container: {
    marginTop: 24,
    marginBottom: 24,
  },
});

function App() {
  const classes = useStyles();

  const renderReactQueryDevtools = () => {
    if (CONFIG.isProduction) {
      return;
    }
    return <ReactQueryDevtools />;
  };

  return (
    <ApiContextProvider>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <UserLanguageContextProvider>
            <StyledEngineProvider injectFirst>
              <CssBaseline />
              <ThemeProvider theme={THEME}>
                <Router basename={CONFIG.routerBasePath}>
                  <Header title="ConWorkShop" />
                  <Container className={classes.container} maxWidth="lg">
                    <Switch>
                      <Route path={ROUTES.LANGUAGES.route}>
                        <LanguagesHomeRouter />
                      </Route>
                      <Route path={ROUTES.ROOT.route}>
                        <Redirect to={ROUTES.LANGUAGES.route} />
                      </Route>
                    </Switch>
                  </Container>
                  {renderReactQueryDevtools()}
                </Router>
              </ThemeProvider>
            </StyledEngineProvider>
          </UserLanguageContextProvider>
        </UserContextProvider>
      </QueryClientProvider>
    </ApiContextProvider>
  );
}

export default App;
