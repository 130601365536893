import { Theme } from "@mui/material";

export type ComponentWidth = "standard" | "fill" | number;

export type ComponentSize = "small" | "medium";

export type ComponentFontWeight = "bold" | "normal";

export type ComponentFontColor = "default" | "primary" | "secondary" | "grey";

export type ComponentDisplay = "block" | "inline";

export type ComponentProps = {
  width?: ComponentWidth;
  size?: ComponentSize;
};

export function toWidthProperty(
  props: { width?: ComponentWidth },
  standard: number
) {
  if (typeof props.width === "number") {
    return props.width;
  }
  if (props.width === "fill") {
    return "100%";
  }
  return standard;
}

export function toFontSize(props: { size?: ComponentSize }) {
  if (props.size === "small") {
    return 12;
  }
  return 15;
}

export function toFontWeight(
  props: { fontWeight?: ComponentFontWeight },
  isBoldDefault = false
) {
  if (
    (props.fontWeight === undefined && isBoldDefault) ||
    props.fontWeight === "bold"
  ) {
    return 600;
  }
  return 400;
}

export function toFontColor(
  props: { fontColor?: ComponentFontColor },
  theme: Theme
) {
  switch (props.fontColor) {
    case "grey":
      return theme.palette.grey[50];
    case "primary":
      return theme.palette.primary.main;
    case "secondary":
      return theme.palette.primary.main;
    default:
      return theme.palette.common.black;
  }
}
