import { WordPartOfSpeech } from "@conworkshop/api-js";
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-pro";

import PartOfSpeechEdit from "../../../../Components/Widgets/PartOfSpeechEdit";

function PartOfSpeechDataTableEditControl(props: GridRenderEditCellParams) {
  const { id, value, field, error } = props;
  const apiRef = useGridApiContext();

  const onChange = (newValue: WordPartOfSpeech | undefined) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <PartOfSpeechEdit value={value} onChange={onChange} error={error} />;
}

export default PartOfSpeechDataTableEditControl;
