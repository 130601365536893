import {
  DictionarySettingsModel,
  LanguageModel,
  LanguageReference,
  WordClassModel,
  WordDialectReference,
  WordFacet,
} from "@conworkshop/api-js";
import { phonology } from "@conworkshop/sdk-js";
import React, { createContext, useContext, useState } from "react";

import { canEditLanguage } from "../Api/access_fns";
import { toLanguageReference } from "../Api/types";
import useApiListDictionaryClasses from "../Api/useApiListDictionaryClasses";
import useApiListLanguageDialects from "../Api/useApiListLanguageDialects";
import useApiRetrieveDictionarySettings from "../Api/useApiRetrieveDictionarySettings";
import useApiRetrieveLanguage from "../Api/useApiRetrieveLanguage";
import useSdkPhonology from "../Api/useSdkPhonology";
import QueryResultRenderer from "../Components/QueryHandling/QueryResultRenderer";
import useQuerySuccess from "../Components/QueryHandling/useQuerySuccess";

export type LanguageCode = string | null;

export type LanguageName = string | null;

export type LanguageContextState = {
  language: LanguageReference;
  phonology: phonology.Phonology;
  classes: WordClassModel[];
  dialects: WordDialectReference[];
  facets: WordFacet[];
  isLanguageEditable: boolean;
};

export type LanguageContextProviderProps = {
  code: string;
  children: (() => React.ReactNode) | React.ReactNode;
};

const LanguageContext =
  createContext<LanguageContextState | undefined>(undefined);

function LanguageContextProvider({
  code,
  children,
}: LanguageContextProviderProps) {
  const query1 = useApiRetrieveLanguage({ code });
  const query2 = useApiListDictionaryClasses({ code });
  const query3 = useApiListLanguageDialects({ code });
  const query4 = useApiRetrieveDictionarySettings({ code });
  const query5 = useSdkPhonology(code);
  const [language, setLanguage] = useState<LanguageModel | undefined>();
  const [isLanguageEditable, setIsLanguageEditable] = useState(false);
  const [classes, setClasses] = useState<WordClassModel[] | undefined>();
  const [dialects, setDialects] =
    useState<WordDialectReference[] | undefined>();
  const [dictionarySettings, setDictionarySettings] =
    useState<DictionarySettingsModel | undefined>();
  const [phonology, setPhonology] = useState<phonology.Phonology | undefined>();

  useQuerySuccess(query1, (res) => {
    const { data, access } = res;
    setLanguage(data);
    setIsLanguageEditable(canEditLanguage(data.code, access));
  });

  useQuerySuccess(query2, (res) => {
    const { data } = res;
    setClasses(data);
  });

  useQuerySuccess(query3, (res) => {
    const { data } = res;
    setDialects(data);
  });

  useQuerySuccess(query4, (res) => {
    const { data } = res;
    setDictionarySettings(data);
  });

  useQuerySuccess(query5, (obj) => {
    setPhonology(obj);
  });

  const isStateReady = !!(
    language &&
    classes &&
    dialects &&
    dictionarySettings &&
    phonology
  );

  const state = isStateReady
    ? {
        language: toLanguageReference(language),
        phonology,
        classes,
        dialects,
        facets: dictionarySettings.facets,
        isLanguageEditable,
      }
    : undefined;

  return (
    <QueryResultRenderer query={query1} isStateReady={isStateReady}>
      {() => (
        <LanguageContext.Provider value={state}>
          {typeof children === "function" ? children() : children}
        </LanguageContext.Provider>
      )}
    </QueryResultRenderer>
  );
}

function useLanguageContext(): LanguageContextState {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("LanguageContext is empty");
  }
  return context;
}

export { LanguageContextProvider, useLanguageContext };
