import "./index.css";

import { LicenseInfo } from "@mui/x-license-pro";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

LicenseInfo.setLicenseKey(
  "f9b48a17f3d16219e7a745f88d39bc5fTz00NjIzMixFPTE2ODc2NTYxMTc4NTQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
