import { ApiResponse, WordModel } from "@conworkshop/api-js";
import { useMutation, UseMutationResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type PatchWordResponse = ApiResponse<WordModel>;

export type WordUpdatedInitParams = {
  onSuccess?: (previousWord: WordModel, updatedWord: WordModel) => void;
  onError?: (word: WordModel, error: string) => void;
};

export type WordUpdateParams = {
  code: string;
  word: WordModel;
};

function useApiUpdateDictionaryWord(): UseMutationResult<
  PatchWordResponse,
  WordModel,
  WordUpdateParams
> {
  const apiClient = useApiClient();
  return useMutation(async (params: WordUpdateParams) => {
    const { code, word } = params;
    return apiClient.updateDictionaryWord(code, word.id, {
      word: word.word,
      partOfSpeech: word.partOfSpeech?.code,
      wordlink: word.wordlink?.id,
      classes: word.classes?.map((c) => c.code),
      alternateWord: word.alternateWord,
      etymology: word.etymology,
      imageUrl: word.imageUrl,
      ipa: word.ipa,
      notes: word.notes,
      register: word.register?.code,
      sample: word.sample,
      sourceLanguage: word.sourceLanguage,
      xsampa: word.xsampa,
    });
  });
}

export default useApiUpdateDictionaryWord;
