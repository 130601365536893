import {
  ApiDictionaryWordSortFacet,
  ApiSortDir,
  WordModel,
} from "@conworkshop/api-js";
import EditConfirmIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Clear";
import DeleteConfirmIcon from "@mui/icons-material/Delete";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  DataGridPro,
  GridActionsCellItem,
  GridActionsColDef,
  GridEventListener,
  GridRowId,
  GridRowModes,
  GridRowParams,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import React, { useCallback } from "react";

import { useDictionaryContext } from "./DictionaryContext";
import { DictionaryColumnName } from "./types";
import { useDictionaryColumns } from "./useDictionaryColumns";

enum GridRowDeleteModes {
  VIEW = "view",
  DELETE = "delete",
}

type GridRowDeleteModesModel = {
  [id: GridRowId]: {
    mode: GridRowDeleteModes;
  };
};

const StyledBox = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${
      theme.palette.mode === "dark" ? 0 : 0.1
    })`,
    color: theme.palette.error.main,
  },
}));

function DictionaryTable() {
  // Retrieve current dictionary query context
  const {
    rows,
    rowModes,
    cursor,
    query,
    settings,
    isLanguageEditable,
    removeWord,
    saveWord,
    transitionStateViewWord,
    transitionStateEditWord,
    updateDictionaryQuery,
    updateColumnWidth,
    updateColumnOrder,
  } = useDictionaryContext();
  const { total, page, limit } = cursor ?? { pageCount: 1, page: 1, limit: 5 };

  const [rowDeleteModesModel, setDeleteRowModesModel] =
    React.useState<GridRowDeleteModesModel>({});

  const setRowDelete = useCallback(
    (id: GridRowId) => {
      transitionStateViewWord(id, false);
      setDeleteRowModesModel({
        ...rowDeleteModesModel,
        [id]: { mode: GridRowDeleteModes.DELETE },
      });
    },
    [rowDeleteModesModel, transitionStateViewWord, setDeleteRowModesModel]
  );

  // Grid callback hooks
  const onProcessRowUpdate = (word: WordModel): Promise<WordModel> => {
    return saveWord(word);
  };

  const onProcessRowUpdateError = (error: Error) => {
    // TODO Error notification
  };

  const onPageNumberChangeHandler = useCallback(
    (page: number) => {
      updateDictionaryQuery({ ...query, pageNumber: page + 1 });
    },
    [query, updateDictionaryQuery]
  );

  const onPageLimitChangeHandler = (newPageSize: number) => {
    updateDictionaryQuery({ ...query, pageSize: newPageSize });
  };

  const onSortChangeHandler = (sortModel: GridSortModel) => {
    const { field, sort } = sortModel[0] ?? { field: "word", sort: "asc" };
    const sortFacet = field as ApiDictionaryWordSortFacet;
    const sortDir = sort === "desc" ? ApiSortDir.DESC : ApiSortDir.ASC;
    updateDictionaryQuery({ ...query, sortFacet, sortDir });
  };

  const onDisableRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const onDisableRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const onEditClick = (id: GridRowId) => () => {
    transitionStateEditWord(id);
  };

  const onEditCancelClient = (id: GridRowId) => () => {
    transitionStateViewWord(id, true);
  };

  const onEditConfirmClick = (id: GridRowId) => () => {
    transitionStateViewWord(id, false);
  };

  const onDeleteClick = (id: GridRowId) => () => {
    setRowDelete(id);
  };

  const onDeleteCancelClient = (id: GridRowId) => () => {
    setDeleteRowModesModel({
      ...rowDeleteModesModel,
      [id]: { mode: GridRowDeleteModes.VIEW },
    });
  };

  const onDeleteConfirmClick = (id: GridRowId) => () => {
    removeWord(id);
  };

  const onColumnWidthChange: GridEventListener<"columnWidthChange"> = (
    params
  ) => {
    updateColumnWidth(
      params.colDef.field as DictionaryColumnName,
      params.width
    );
  };

  const onColumnOrderChange: GridEventListener<"columnOrderChange"> = (
    params
  ) => {
    const field = params.colDef.field as DictionaryColumnName;
    const order = [...settings.columnOrder];
    order.splice(order.indexOf(field), 1);
    order.splice(params.targetIndex - 1, 0, field);
    updateColumnOrder(order);
  };

  const action: GridActionsColDef = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModes[id]?.mode === GridRowModes.Edit;
      const isInDeleteMode =
        rowDeleteModesModel[id]?.mode === GridRowDeleteModes.DELETE;

      if (isInDeleteMode) {
        return [
          <GridActionsCellItem
            icon={<DeleteConfirmIcon />}
            label="Delete"
            className="textPrimary"
            onClick={onDeleteConfirmClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            onClick={onDeleteCancelClient(id)}
          />,
        ];
      }

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<EditConfirmIcon />}
            label="Save"
            onClick={onEditConfirmClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={onEditCancelClient(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={onEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={onDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  };
  const [columns, visibility] = useDictionaryColumns();

  if (isLanguageEditable) {
    columns.unshift(action);
  }

  return (
    <StyledBox>
      <DataGridPro
        rows={rows}
        rowModesModel={rowModes}
        columns={columns}
        sortModel={[
          {
            field: query.sortFacet ?? "word",
            sort: query.sortDir ?? "asc",
          },
        ]}
        sortingOrder={["asc", "desc"]}
        disableColumnMenu={true}
        columnVisibilityModel={visibility}
        pinnedColumns={{ left: ["actions", "word"] }}
        pagination
        rowCount={total ?? 0}
        page={page > 0 ? page - 1 : 1}
        pageSize={limit}
        paginationMode="server"
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        onPageChange={(newPage) => onPageNumberChangeHandler(newPage)}
        onPageSizeChange={(newPageSize) =>
          onPageLimitChangeHandler(newPageSize)
        }
        sortingMode="server"
        onSortModelChange={onSortChangeHandler}
        onColumnWidthChange={onColumnWidthChange}
        onColumnOrderChange={onColumnOrderChange}
        editMode="row"
        onRowEditStart={onDisableRowEditStart}
        onRowEditStop={onDisableRowEditStop}
        processRowUpdate={onProcessRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </StyledBox>
  );
}

export default DictionaryTable;
