import { WordFacet, WordPartOfSpeech } from "@conworkshop/api-js";
import Add from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import TextField from "../../../../Components/Data/TextField";
import PartOfSpeechEdit from "../../../../Components/Widgets/PartOfSpeechEdit";
import { useDictionaryContext } from "./DictionaryContext";
import WordFacetSelector from "./WordFacetSelector";

function DictionaryToolbar() {
  const {
    query,
    isLanguageEditable,
    availableWordFacets,
    addWord,
    updateDictionaryQuery,
  } = useDictionaryContext();

  const [currentSearch, setCurrentSearch] = useState<string | undefined>(
    query.search
  );

  const enqueueSearch = useDebouncedCallback(() => {
    updateDictionaryQuery({
      ...query,
      search: currentSearch
        ? currentSearch.trim() !== ""
          ? currentSearch
          : undefined
        : undefined,
    });
  }, 300);

  const onSearchChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentSearch(event.target.value);
    enqueueSearch();
  };

  const onWordFacetsChangeHandler = (updatedFacets: WordFacet[]) => {
    updateDictionaryQuery({ ...query, wordFacets: updatedFacets });
  };

  const onFilterChangeHandler = (
    updatedFilterValue: WordPartOfSpeech | undefined
  ) => {
    updateDictionaryQuery({
      ...query,
      filterValue: updatedFilterValue ? updatedFilterValue.code : undefined,
    });
  };

  return (
    <React.Fragment>
      <div>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={7} sm={7} md={2}>
            <TextField
              size="small"
              width="fill"
              placeholder="Search"
              label="Search"
              value={currentSearch}
              onChange={onSearchChangeHandler}
            />
          </Grid>
          <Grid item xs={5} sm={5} md={2}>
            <PartOfSpeechEdit
              label="Filter"
              size="small"
              width="fill"
              value={query.filterValue}
              onChange={onFilterChangeHandler}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <WordFacetSelector
              width="fill"
              value={query.wordFacets}
              facets={availableWordFacets}
              onChange={onWordFacetsChangeHandler}
            />
          </Grid>
          {isLanguageEditable ? (
            <Grid item sm={4} md={2} justifySelf="right">
              <Button
                variant="contained"
                size="medium"
                fullWidth={true}
                color="secondary"
                onClick={addWord}
              >
                <Add />
                &nbsp;Add Word
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default DictionaryToolbar;
