import { Link, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { useParams } from "react-router";

import useApiRetrieveDictionaryWord from "../../../Api/useApiRetrieveDictionaryWord";
import FieldGrid from "../../../Components/Data/FieldGrid";
import FieldGridCell from "../../../Components/Data/FieldGridCell";
import Panel from "../../../Components/Layout/Panel";
import QueryResultRenderer from "../../../Components/QueryHandling/QueryResultRenderer";
import Text from "../../../Components/Typography/Text";
import LanguageDialectView from "../../../Components/Widgets/LanguageDialectView";
import PartOfSpeechView from "../../../Components/Widgets/PartOfSpeechView";
import RegisterView from "../../../Components/Widgets/RegisterView";
import WordClassesView from "../../../Components/Widgets/WordClassesView";
import { useLanguageContext } from "../../../Contexts/LanguageContext";

function ViewWordScreen() {
  const { id, code } = useParams<{ id: string; code: string }>();

  const query = useApiRetrieveDictionaryWord({ id, code });

  const { phonology } = useLanguageContext();

  return (
    <React.Fragment>
      <QueryResultRenderer query={query}>
        {(word) => (
          <React.Fragment>
            <Stack spacing={3}>
              <Panel
                elevated={true}
                title={word.data.word}
                subTitle={
                  word.data.ipa
                    ? `/ ${word.data.ipa} /`
                    : `/ ${phonology.estimatePhonetics(
                        word.data.word
                      )} / (estimated)`
                }
                upperRight={
                  <React.Fragment>
                    <PartOfSpeechView
                      format="chip"
                      value={word.data.partOfSpeech}
                    />
                    <WordClassesView value={word.data.classes} />
                  </React.Fragment>
                }
              >
                <Grid container spacing={4}>
                  <Grid item sm={6}>
                    <Text display="inline">{word.data.wordlink.word}</Text>{" "}
                    {word.data.wordlink.hint ? (
                      <Text
                        display="inline"
                        fontColor="grey"
                      >{`(${word.data.wordlink.hint})`}</Text>
                    ) : undefined}
                  </Grid>
                </Grid>
              </Panel>
              <Panel>
                <FieldGrid>
                  <FieldGridCell
                    label="Alternate Word"
                    value={word.data.alternateWord}
                    size="small"
                  />

                  <FieldGridCell
                    label="Dialect"
                    size="small"
                    value={word.data.dialect}
                  >
                    <LanguageDialectView value={word.data.dialect} />
                  </FieldGridCell>

                  <FieldGridCell
                    label="Register"
                    size="small"
                    value={word.data.register}
                  >
                    <RegisterView value={word.data.register} />
                  </FieldGridCell>

                  <FieldGridCell
                    label="Source Language"
                    value={word.data.sourceLanguage}
                    size="small"
                  />

                  <FieldGridCell
                    label="X-Sampa"
                    value={word.data.xsampa}
                    size="small"
                  />

                  <FieldGridCell
                    label="Notes"
                    value={word.data.notes}
                    size="large"
                  />

                  <FieldGridCell
                    label="Sample"
                    value={word.data.sample}
                    size="large"
                  />

                  <FieldGridCell
                    label="Etymology"
                    value={word.data.etymology}
                    size="large"
                  />

                  <FieldGridCell
                    label="Image URL"
                    value={word.data.imageUrl}
                    size="large"
                  >
                    <Link href={word.data.imageUrl} target="_blank">
                      {word.data.imageUrl}
                    </Link>
                  </FieldGridCell>
                </FieldGrid>
              </Panel>
            </Stack>
          </React.Fragment>
        )}
      </QueryResultRenderer>
    </React.Fragment>
  );
}

export default ViewWordScreen;
