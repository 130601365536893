import { WordlinkModel, WordlinkReference } from "@conworkshop/api-js";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import useApiQueryWordlinks from "../../Api/useApiQueryWordlinks";
import useQuerySuccess from "../QueryHandling/useQuerySuccess";
import { ComponentProps, toFontSize } from "../styles";

export type WordlinkEditProps = ComponentProps & {
  label?: string;
  value: WordlinkReference | undefined;
  error?: boolean;
  disabled?: boolean;
  onChange: (value: WordlinkReference | undefined) => void;
};

const useStyles = makeStyles((theme) => ({
  root: (props: WordlinkEditProps) => ({
    fontSize: toFontSize(props),
  }),
  input: (props: WordlinkEditProps) => ({
    fontSize: toFontSize(props),
  }),
}));

function WordlinkEdit(props: WordlinkEditProps) {
  const { value, label, error, disabled, onChange, ...other } = props;
  const classes = useStyles(props);
  const [queryValue, setQueryValue] = useState(value?.word ?? "");
  const [enqueuedQueryValue, setEnqueuedQueryValue] = useState("");
  const [currentValue, setCurrentValue] = useState<WordlinkModel | null>(
    (value as any) ?? null
  );
  const [options, setOptions] = React.useState<readonly WordlinkModel[]>([]);

  const query = useApiQueryWordlinks({
    search: queryValue,
    limit: 10,
    page: 1,
  });

  useQuerySuccess(query, (wordlinks) => {
    setOptions(wordlinks.data);
  });

  const enqueueSearch = useDebouncedCallback(() => {
    setQueryValue(enqueuedQueryValue);
  }, 300);

  return (
    <Autocomplete
      classes={{
        root: classes.root,
      }}
      style={{ width: "100%" }}
      value={currentValue}
      options={options}
      disabled={disabled}
      filterOptions={(x) => x}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.word
      }
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      autoComplete
      autoSelect
      // disableClearable
      onChange={(event, newValue: WordlinkModel | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setCurrentValue(newValue);
        onChange(newValue ? newValue : undefined);
      }}
      onInputChange={(event, queryValue) => {
        setEnqueuedQueryValue(queryValue);
        enqueueSearch();
      }}
      noOptionsText="Search for word link"
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.input}
          label={label}
          fullWidth
          error={error}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li
            {...{ ...props, key: option.id }}
            style={{ fontSize: toFontSize(other) }}
          >
            <span>
              <span>{option.word}</span>{" "}
              <span style={{ color: "#666" }}>
                [{option.partOfSpeech.code}]
              </span>
              <br />
              <span style={{ color: "#666" }}>
                {option.hint ? `${option.hint}` : ""}
              </span>
            </span>
          </li>
        );
      }}
      {...other}
    />
  );
}

export default WordlinkEdit;
