import { WordRegister } from "@conworkshop/api-js";
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-pro";

import RegisterEdit from "../../../../Components/Widgets/RegisterEdit";

function RegisterDataTableEditControl(props: GridRenderEditCellParams) {
  const { id, value, field, error } = props;
  const apiRef = useGridApiContext();

  const onChange = (newValue: WordRegister | undefined) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <RegisterEdit value={value} onChange={onChange} error={error} />;
}

export default RegisterDataTableEditControl;
