import { Grid } from "@mui/material";
import React from "react";

import FieldDisplay from "./FieldDisplay";

export type FieldGridProps = {
  label: string;
  size: "small" | "large";
  value?: any;
  children?: React.ReactElement | React.ReactElement[];
};

function FieldGridCell(props: FieldGridProps) {
  const { label, size, children, value } = props;

  const xsCols = size === "small" ? 12 : 12;
  const smCols = size === "small" ? 12 : 12;
  const mdCols = size === "small" ? 6 : 12;
  const lgCols = size === "small" ? 6 : 12;

  if (value === undefined) {
    return null;
  }

  if (children) {
    return (
      <Grid item xs={xsCols} sm={smCols} md={mdCols} lg={lgCols}>
        <FieldDisplay label={label}>{children}</FieldDisplay>
      </Grid>
    );
  }

  if (typeof value === "string" && value.trim() !== "") {
    return (
      <Grid item xs={xsCols} sm={smCols} md={mdCols} lg={lgCols}>
        <FieldDisplay label={label} value={value} />
      </Grid>
    );
  }

  return null;
}

export default FieldGridCell;
