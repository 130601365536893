import { ApiSortDir } from "@conworkshop/api-js";

import {
  DictionaryColumnName,
  DictionaryColumnWidthSettings,
  DictionaryQuery,
  DictionarySettings,
} from "./types";

export type DictionaryLocalStorage = {
  readLocalStorageDictionarySettings: () => DictionarySettings;
  saveLocalStorageDictionarySettings: (settings: DictionarySettings) => void;
  readLocalStorageDictionaryQuery: (code: string) => DictionaryQuery;
  saveLocalStorageDictionaryQuery: (
    code: string,
    query: DictionaryQuery
  ) => void;
};

export function useDictionaryLocalStorage(): DictionaryLocalStorage {
  const readLocalStorageDictionaryQuery = (code: string): DictionaryQuery => {
    const defaultQuery = {
      search: undefined,
      pageNumber: 1,
      pageSize: 20,
      wordFacets: [],
      filterValue: undefined,
      sortFacet: "word",
      sortDir: ApiSortDir.ASC,
    };
    const json = localStorage.getItem(`user.dictionaryQueries.${code}`);
    const savedQuery = json ? JSON.parse(json) : {};
    return { ...defaultQuery, ...savedQuery };
  };

  const saveLocalStorageDictionaryQuery = (
    code: string,
    query: DictionaryQuery
  ): void => {
    localStorage.setItem(
      `user.dictionaryQueries.${code}`,
      JSON.stringify(query)
    );
  };

  function readLocalStorageDictionarySettings(): DictionarySettings {
    const columnOrder = [
      DictionaryColumnName.WORD,
      DictionaryColumnName.WORD_LINK,
      DictionaryColumnName.PART_OF_SPEECH,
      DictionaryColumnName.ALTERNATE_WORD,
      DictionaryColumnName.CLASSES,
      DictionaryColumnName.DIALECT,
      DictionaryColumnName.ETYMOLOGY,
      DictionaryColumnName.IMAGE_URL,
      DictionaryColumnName.IPA,
      DictionaryColumnName.NOTES,
      DictionaryColumnName.REGISTER,
      DictionaryColumnName.SAMPLE,
      DictionaryColumnName.SOURCE_LANGUAGE,
      DictionaryColumnName.XSAMPA,
      DictionaryColumnName.CREATED_AT,
    ];
    const columnWidths: DictionaryColumnWidthSettings = {
      [DictionaryColumnName.WORD]: 150,
      [DictionaryColumnName.WORD_LINK]: 200,
      [DictionaryColumnName.PART_OF_SPEECH]: 150,
      [DictionaryColumnName.ALTERNATE_WORD]: 150,
      [DictionaryColumnName.CLASSES]: 150,
      [DictionaryColumnName.CREATED_AT]: 150,
      [DictionaryColumnName.DIALECT]: 150,
      [DictionaryColumnName.ETYMOLOGY]: 150,
      [DictionaryColumnName.IMAGE_URL]: 150,
      [DictionaryColumnName.IPA]: 150,
      [DictionaryColumnName.NOTES]: 150,
      [DictionaryColumnName.REGISTER]: 150,
      [DictionaryColumnName.SAMPLE]: 150,
      [DictionaryColumnName.SOURCE_LANGUAGE]: 150,
      [DictionaryColumnName.XSAMPA]: 150,
    };
    const json = localStorage.getItem(`user.dictionarySettings`);
    const savedQuery = json ? JSON.parse(json) : {};
    return { ...{ columnWidths, columnOrder }, ...savedQuery };
  }

  function saveLocalStorageDictionarySettings(settings: DictionarySettings) {
    localStorage.setItem(`user.dictionarySettings`, JSON.stringify(settings));
  }

  return {
    readLocalStorageDictionarySettings,
    saveLocalStorageDictionarySettings,
    readLocalStorageDictionaryQuery,
    saveLocalStorageDictionaryQuery,
  };
}
