import { WordPartOfSpeech } from "@conworkshop/api-js";
import React from "react";

import { getPartOfSpeech, POS_LIST } from "../../Api/data/parts_of_speech";
import Select from "../Data/Select";
import { ComponentProps } from "../styles";

// const options: WordPartOfSpeech[] = [, ...POS_LIST];

export type PartOfSpeechEditProps = ComponentProps & {
  label?: string;
  value?: string | WordPartOfSpeech;
  error?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  onChange: (value: WordPartOfSpeech | undefined) => void;
};

function PartOfSpeechEdit(props: PartOfSpeechEditProps) {
  const { label, value, disabled, onBlur, onChange, ...other } = props;

  const onChangeHandler = (value: WordPartOfSpeech | undefined) => {
    if (!value) {
      onChange(undefined);
      return;
    }
    onChange(value);
  };

  const partOfSpeech =
    typeof value === "string" ? getPartOfSpeech(value, false) : value;

  return (
    <Select
      label={label}
      style={{ width: "100%" }}
      placeholder="None"
      allowEmpty={true}
      value={partOfSpeech}
      options={POS_LIST}
      disabled={disabled}
      getOptionLabel={(o) => o.label}
      getOptionValue={(o) => o.code}
      onChangeValue={onChangeHandler}
      onBlur={onBlur}
      {...other}
    />
  );
}

export default PartOfSpeechEdit;
