import { createTheme, ThemeOptions } from "@mui/material/styles";

export const themeOptions: ThemeOptions = {
  palette: {
    // type: 'light',
    primary: {
      main: "#00838f",
    },
    secondary: {
      main: "#3b0053",
    },
    success: {
      main: "#c2e812",
    },
    grey: {
      "50": "#757575",
    },
  },
  typography: {
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontSize: 13,
    fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
  },
};

const THEME = createTheme(themeOptions);

export default THEME;
