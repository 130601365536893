import { WordRegister } from "@conworkshop/api-js";
import React from "react";

import {
  REGISTER_DEFAULT,
  REGISTER_LIST,
  REGISTER_NEUTRAL,
} from "../../Api/data/registers";
import Select from "../Data/Select";
import { ComponentProps } from "../styles";

export type RegisterEditProps = ComponentProps & {
  label?: string;
  value?: WordRegister;
  error?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  onChange: (value: WordRegister) => void;
};

function RegisterEdit(props: RegisterEditProps) {
  const { label, value, disabled, onBlur, onChange, ...other } = props;

  const onChangeHandler = (value: WordRegister | undefined) => {
    if (!value || value.code === REGISTER_NEUTRAL.code) {
      onChange(REGISTER_DEFAULT);
      return;
    }
    onChange(value);
  };

  return (
    <Select
      label={label}
      allowEmpty={false}
      value={!value || value.code === "" ? REGISTER_NEUTRAL : value}
      options={REGISTER_LIST}
      disabled={disabled}
      getOptionLabel={(o) => o.label}
      getOptionValue={(o) => (o.code === "" ? "N" : o.code)}
      onChangeValue={onChangeHandler}
      onBlur={onBlur}
      {...other}
    />
  );
}

export default RegisterEdit;
