import { phonology } from "@conworkshop/sdk-js";
import { useQuery, UseQueryResult } from "react-query";

import { useConWorkshopSdk } from "./ApiContext";

function useSdkPhonology(
  code: string
): UseQueryResult<phonology.Phonology> {
  const cwsSdk = useConWorkshopSdk();
  return useQuery([`phonology.${code}`],
    async () => {
      return cwsSdk.phonology(code);
    },
  );
}

export default useSdkPhonology;
