import { WordDialectReference } from "@conworkshop/api-js";
import React from "react";

import Select from "../Data/Select";
import { ComponentProps } from "../styles";

export type DialectEditProps = ComponentProps & {
  label?: string;
  value?: WordDialectReference;
  dialects: WordDialectReference[];
  error?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  onChange: (value: WordDialectReference | undefined) => void;
};

function LanguageDialectEdit(props: DialectEditProps) {
  const {
    label,
    value,
    dialects,
    disabled,
    error,
    onBlur,
    onChange,
    ...other
  } = props;

  const onChangeHandler = (value: WordDialectReference | undefined) => {
    onChange(value);
  };

  return (
    <Select
      label={label}
      error={error}
      allowEmpty={true}
      value={value}
      options={dialects}
      disabled={disabled}
      placeholder="No Dialect"
      getOptionLabel={(o) => o.name}
      getOptionValue={(o) => o.code}
      onChangeValue={onChangeHandler}
      onBlur={onBlur}
      {...other}
    />
  );
}

export default LanguageDialectEdit;
