import MuTextField, {
  TextFieldProps as MuTextFieldProps,
} from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";

import { ComponentProps, toFontSize, toWidthProperty } from "../styles";

export type TextFieldProps = MuTextFieldProps &
  ComponentProps & {
    value?: string;
    type?: string;
    autoCapitalize?: string;
  };

const useStyles = makeStyles((theme) => ({
  root: (props: TextFieldProps) => ({
    fontSize: toFontSize(props),
    width: toWidthProperty(props, 200),
  }),
  input: (props: TextFieldProps) => ({
    fontSize: toFontSize(props),
  }),
}));

function TextField(props: TextFieldProps) {
  const { value, autoCapitalize, type, onChange, onBlur, ...other } = props;
  const classes = useStyles(props);
  const [currentValue, setCurrentValue] = useState<string>(props.value ?? "");

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCurrentValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const onBlurHandler = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCurrentValue(currentValue.trim());
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <MuTextField
      className={classes.root}
      inputProps={{
        className: classes.input,
        autoComplete: "off",
        autoCapitalize,
        type: type ?? "text",
      }}
      {...other}
      value={currentValue}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
    />
  );
}

export default TextField;
