import { ApiEmptyResponse } from "@conworkshop/api-js";
import { useMutation, UseMutationResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type DeleteDictionaryWordResponse = ApiEmptyResponse;

export type WordDeleteParams = {
  onSuccess?: () => void;
  onError?: (id: string, error: string) => void;
};

export type WordRef = {
  id: string;
  code: string;
};

function useApiDeleteDictionaryWord(
  params?: WordDeleteParams
): UseMutationResult<DeleteDictionaryWordResponse, unknown, WordRef> {
  const apiClient = useApiClient();
  return useMutation(async (ref: WordRef) => {
    const { code, id } = ref;
    return apiClient.deleteDictionaryWord(code, id);
  });
}

export default useApiDeleteDictionaryWord;
