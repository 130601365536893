import { WordDialectReference } from "@conworkshop/api-js";
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-pro";

import LanguageDialectEdit from "../../../../Components/Widgets/LangaugeDialectEdit";
import { useLanguageContext } from "../../../../Contexts/LanguageContext";

function LanguageDialectDataTableEditControl(props: GridRenderEditCellParams) {
  const { id, value, field, error } = props;
  const apiRef = useGridApiContext();

  const { dialects } = useLanguageContext();

  const onChange = (newValue: WordDialectReference | undefined) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <LanguageDialectEdit
      value={value}
      dialects={dialects}
      onChange={onChange}
      error={error}
    />
  );
}

export default LanguageDialectDataTableEditControl;
