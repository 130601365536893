import { Theme } from "@mui/material";
import MuTypography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

import {
  ComponentFontColor,
  ComponentFontWeight,
  ComponentSize,
  toFontColor,
  toFontSize,
  toFontWeight,
} from "../styles";

export type H2Props = {
  size?: ComponentSize;
  fontWeight?: ComponentFontWeight;
  fontColor?: ComponentFontColor;
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: H2Props) => ({
    fontSize: toFontSize(props) * 1.2,
    fontWeight: toFontWeight(props, true),
    color: toFontColor(props, theme),
  }),
}));

function H2(props: H2Props) {
  const { children } = props;
  const classes = useStyles(props);

  return <MuTypography className={classes.root}>{children}</MuTypography>;
}

export default H2;
