import { Theme } from "@mui/material";
import MuTypography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

import {
  ComponentFontColor,
  ComponentSize,
  toFontColor,
  toFontSize,
} from "../styles";

export type H1Props = {
  size?: ComponentSize;
  fontColor?: ComponentFontColor;
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: H1Props) => ({
    fontSize: toFontSize(props) * 1.5,
    fontWeight: 600,
    color: toFontColor(props, theme),
  }),
}));

function H1(props: H1Props) {
  const { children } = props;
  const classes = useStyles(props);

  return <MuTypography className={classes.root}>{children}</MuTypography>;
}

export default H1;
