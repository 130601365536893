import { Theme } from "@mui/material";
import MuTypography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

import { ComponentFontWeight, ComponentSize } from "../styles";
import Text from "../Typography/Text";

export type FieldLabelProps = {
  label: string;
  size?: ComponentSize;
  fontWeight?: ComponentFontWeight;
  value?: string;
  children?: React.ReactElement | React.ReactElement[];
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 0,
  },
  label: {
    fontSize: 12,
    color: theme.palette.grey[50],
  },
}));

function FieldDisplay(props: FieldLabelProps) {
  const { children, value, label } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <MuTypography
        className={classes.label}
        variant="overline"
        display="block"
        gutterBottom
      >
        {label}
      </MuTypography>
      {value ? <Text>{value}</Text> : children}
    </div>
  );
}

export default FieldDisplay;
