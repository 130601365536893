export type AppConfig = {
  isProduction: boolean;
  apiBaseUrl?: string;
  routerBasePath: string;
};

function _initConfig(): AppConfig {
  if (process.env.NODE_ENV !== "production") {
    return {
      isProduction: false,
      apiBaseUrl: "http://localhost:8080/api2",
      routerBasePath: "",
    };
  }
  return {
    isProduction: true,
    routerBasePath: "",
  };
}

const CONFIG = _initConfig();

export default CONFIG;
