import { WordClassModel } from "@conworkshop/api-js";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import React from "react";

import { ComponentProps, toFontSize } from "../styles";

export type WordClassesProps = ComponentProps & {
  label?: string;
  value: WordClassModel[];
  disabled?: boolean;
  wordClasses: WordClassModel[];
  error?: boolean;
  onChange: (value: WordClassModel[]) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: (props: ComponentProps) => ({
    fontSize: toFontSize(props),
  }),
  listItemText: (props: ComponentProps) => ({
    fontSize: toFontSize(props),
  }),
}));

function WordClassesEdit(props: WordClassesProps) {
  const { value, wordClasses, onChange } = props;
  const values = value.map((v) => v.code);

  const classes = useStyles(props);

  wordClasses.sort((a, b) => a.label.localeCompare(b.label));

  const onSelect = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const codes = typeof value === "string" ? value.split(",") : value;
    const temp = codes.map((c) => wordClasses.find((wc) => wc.code === c));
    onChange(temp as WordClassModel[]);
  };

  return (
    <Select
      className={classes.root}
      style={{ width: "100%" }}
      multiple
      label=""
      value={values}
      onChange={onSelect}
      input={<OutlinedInput label="Tag" />}
      renderValue={(selected) => selected.join(", ")}
      MenuProps={MenuProps}
    >
      {wordClasses.map((wordClass: WordClassModel) => (
        <MenuItem key={wordClass.code} value={wordClass.code}>
          <Checkbox checked={values.indexOf(wordClass.code) > -1} />
          <ListItemText
            className={classes.listItemText}
            primary={wordClass.label}
            secondary={wordClass.code}
          />
        </MenuItem>
      ))}
    </Select>
  );
}

export default WordClassesEdit;
