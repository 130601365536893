import { LanguageDialectReference } from "@conworkshop/api-js";
import React from "react";

import Chip from "../Data/Chip";
import { ComponentSize } from "../styles";

export type LanguageDialectViewProps = {
  variation?: "abbrev" | "full";
  size?: ComponentSize;
  value?: LanguageDialectReference;
};

function LanguageDialectView(props: LanguageDialectViewProps) {
  const { value, size, variation } = props;

  if (!value) {
    return <React.Fragment />;
  }

  return (
    <Chip
      variant="outlined"
      size={size}
      label={variation === "abbrev" ? value.code : value.name}
    />
  );
}

export default LanguageDialectView;
