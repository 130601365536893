import {
  LanguageDialectReference,
  WordClassModel,
  WordFacet,
  WordlinkReference,
  WordPartOfSpeech,
  WordRegister,
} from "@conworkshop/api-js";
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from "@mui/x-data-grid-pro";
import React from "react";

import DateFormat from "../../../../Components/Data/DateFormat";
import Link from "../../../../Components/Typography/Link";
import Text from "../../../../Components/Typography/Text";
import LanguageDialectView from "../../../../Components/Widgets/LanguageDialectView";
import PartOfSpeechView from "../../../../Components/Widgets/PartOfSpeechView";
import RegisterView from "../../../../Components/Widgets/RegisterView";
import WordClassesView from "../../../../Components/Widgets/WordClassesView";
import ROUTES from "../../../../router";
import { strTruncate } from "../../../../Util/string_fns";
import { useDictionaryContext } from "./DictionaryContext";
import LanguageDialectDataTableEditControl from "./LanguageDialectDataTableEditControl";
import PartOfSpeechDataTableEditControl from "./PartOfSpeechDataTableEditControl";
import RegisterDataTableEditControl from "./RegisterDataTableEditControl";
import { DictionaryColumnName, DictionarySettings } from "./types";
import WordClassesDataTableEditControl from "./WordClassesDataTableEditControl";
import WordlinkDataTableEditControl from "./WordlinkDataTableEditControl";

const FACETS_TO_COLUMNS: Record<WordFacet, DictionaryColumnName> = {
  [WordFacet.ALTERNATE_WORD]: DictionaryColumnName.ALTERNATE_WORD,
  [WordFacet.CLASSES]: DictionaryColumnName.CLASSES,
  [WordFacet.CREATED_AT]: DictionaryColumnName.CREATED_AT,
  [WordFacet.DIALECT]: DictionaryColumnName.DIALECT,
  [WordFacet.ETYMOLOGY]: DictionaryColumnName.ETYMOLOGY,
  [WordFacet.IMAGE_URL]: DictionaryColumnName.IMAGE_URL,
  [WordFacet.IPA]: DictionaryColumnName.IPA,
  [WordFacet.NOTES]: DictionaryColumnName.NOTES,
  [WordFacet.REGISTER]: DictionaryColumnName.REGISTER,
  [WordFacet.SAMPLE]: DictionaryColumnName.SAMPLE,
  [WordFacet.SOURCE_LANGUAGE]: DictionaryColumnName.SOURCE_LANGUAGE,
  [WordFacet.XSAMPA]: DictionaryColumnName.XSAMPA,
};

const wordlinkDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.WORD_LINK,
  headerName: "Definition",
  sortable: true,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<WordlinkReference>) => {
    const { value } = params;
    if (!value) {
      return <React.Fragment />;
    }
    const text = `${value.word}${value.hint ? ` (${value.hint})` : ""}`;
    return <Text size="small">{text}</Text>;
  },
  renderEditCell: (params: GridRenderEditCellParams) => (
    <WordlinkDataTableEditControl {...params} />
  ),
  preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
    const hasError = params.props.value === undefined;
    return { ...params.props, error: hasError };
  },
};

const partOfSpeechDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.PART_OF_SPEECH,
  headerName: "Part Of Speech",
  sortable: true,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<WordPartOfSpeech>) => (
    <PartOfSpeechView size="small" value={params.value} />
  ),
  renderEditCell: (params: GridRenderEditCellParams) => (
    <PartOfSpeechDataTableEditControl {...params} />
  ),
  preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
    const hasError = params.props.value === undefined;
    return { ...params.props, error: hasError };
  },
};

const alternateDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.ALTERNATE_WORD,
  headerName: "Alternate Word",
  sortable: false,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<string>) => (
    <Text size="small">{params.value}</Text>
  ),
};

const classesDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.CLASSES,
  headerName: "Classes",
  sortable: false,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<WordClassModel[]>) => (
    <WordClassesView size="small" value={params.value} />
  ),
  renderEditCell: (params: GridRenderEditCellParams) => (
    <WordClassesDataTableEditControl {...params} />
  ),
};

const createdAtDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.CREATED_AT,
  headerName: "Added At",
  sortable: false,
  editable: false,
  width: 150,
  renderCell: (params: GridRenderCellParams<number>) => (
    <DateFormat size="small" date={params.value} />
  ),
};

const dialectDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.DIALECT,
  headerName: "Dialect",
  sortable: false,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<LanguageDialectReference>) => (
    <LanguageDialectView size="small" value={params.value} />
  ),
  renderEditCell: (params: GridRenderEditCellParams) => (
    <LanguageDialectDataTableEditControl {...params} />
  ),
};

const etymologyDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.ETYMOLOGY,
  headerName: "Etymology",
  sortable: false,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<string>) => (
    <Text size="small">{params.value}</Text>
  ),
};

const imageUrlDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.IMAGE_URL,
  headerName: "Image URL",
  sortable: false,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<string>) => (
    <span>
      {params.value ? (
        <a href={params.value} target="_new">
          <Text size="small">{strTruncate(params.value, 40)}</Text>
        </a>
      ) : (
        ""
      )}
    </span>
  ),
};

const ipaDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.IPA,
  headerName: "IPA",
  sortable: false,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<string>) => (
    <Text size="small">{params.value ? `/ ${params.value} /` : ""}</Text>
  ),
};

const notesDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.NOTES,
  headerName: "Notes",
  sortable: false,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<string>) => (
    <Text size="small">{params.value}</Text>
  ),
};

const registerDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.REGISTER,
  headerName: "Register",
  sortable: false,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<WordRegister>) => (
    <RegisterView size="small" value={params.value} />
  ),
  renderEditCell: (params: GridRenderEditCellParams) => (
    <RegisterDataTableEditControl {...params} />
  ),
};

const sampleDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.SAMPLE,
  headerName: "Sample",
  sortable: false,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<string>) => (
    <Text size="small">{params.value}</Text>
  ),
};

const sourceLanguageDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.SOURCE_LANGUAGE,
  headerName: "Source Language",
  sortable: false,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<string>) => (
    <Text size="small">{params.value}</Text>
  ),
};

const xsampaDictionaryTableColumn: GridColDef = {
  field: DictionaryColumnName.XSAMPA,
  headerName: "X-SAMPA",
  sortable: false,
  editable: true,
  width: 150,
  renderCell: (params: GridRenderCellParams<string>) => (
    <Text size="small">{params.value}</Text>
  ),
};

const TABLE_COLUMNS = {
  [alternateDictionaryTableColumn.field]: alternateDictionaryTableColumn,
  [classesDictionaryTableColumn.field]: classesDictionaryTableColumn,
  [createdAtDictionaryTableColumn.field]: createdAtDictionaryTableColumn,
  [dialectDictionaryTableColumn.field]: dialectDictionaryTableColumn,
  [etymologyDictionaryTableColumn.field]: etymologyDictionaryTableColumn,
  [imageUrlDictionaryTableColumn.field]: imageUrlDictionaryTableColumn,
  [ipaDictionaryTableColumn.field]: ipaDictionaryTableColumn,
  [notesDictionaryTableColumn.field]: notesDictionaryTableColumn,
  [registerDictionaryTableColumn.field]: registerDictionaryTableColumn,
  [sampleDictionaryTableColumn.field]: sampleDictionaryTableColumn,
  [sourceLanguageDictionaryTableColumn.field]:
    sourceLanguageDictionaryTableColumn,
  [xsampaDictionaryTableColumn.field]: xsampaDictionaryTableColumn,
};

function toColumn(
  columnDef: GridColDef,
  settings: DictionarySettings
): GridColDef {
  return {
    ...columnDef,
    width:
      settings.columnWidths[columnDef.field as DictionaryColumnName] ?? 150,
  };
}

export function useDictionaryColumns(): [
  GridColDef[],
  GridColumnVisibilityModel
] {
  const { code, query, settings, availableWordFacets } = useDictionaryContext();

  const visibility: GridColumnVisibilityModel = {};

  // Inline the Word column as it needs the Language Context
  const wordDictionaryTableColumn: GridColDef = {
    field: DictionaryColumnName.WORD,
    headerName: "Word",
    sortable: true,
    editable: true,
    width: 150,
    renderCell: (params: GridRenderCellParams<string>) => (
      <Link
        to={ROUTES.LANGUAGES.LANGUAGE.DICTIONARY.WORD_VIEW.create({
          code,
          id: params.id as string,
        })}
        size="small"
        fontWeight="bold"
      >
        {params.value ?? ""}
      </Link>
    ),
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const hasError = params.props.value.trim() < 1;
      return { ...params.props, error: hasError };
    },
  };

  // Setup the Default Columns that are always present
  const columnDefinitions: Map<string, GridColDef> = new Map();
  columnDefinitions.set(
    wordDictionaryTableColumn.field,
    toColumn(wordDictionaryTableColumn, settings)
  );
  columnDefinitions.set(
    wordlinkDictionaryTableColumn.field,
    toColumn(wordlinkDictionaryTableColumn, settings)
  );
  columnDefinitions.set(
    partOfSpeechDictionaryTableColumn.field,
    toColumn(partOfSpeechDictionaryTableColumn, settings)
  );
  visibility[wordDictionaryTableColumn.field] = true;
  visibility[wordlinkDictionaryTableColumn.field] = true;
  visibility[partOfSpeechDictionaryTableColumn.field] = true;

  availableWordFacets.forEach((facet: WordFacet) => {
    const columnName = FACETS_TO_COLUMNS[facet];
    if (!TABLE_COLUMNS[columnName]) {
      return;
    }
    columnDefinitions.set(
      columnName,
      toColumn(TABLE_COLUMNS[columnName], settings)
    );
    visibility[columnName] = query.wordFacets.indexOf(facet) > -1;
  });

  const columns: GridColDef[] = [];
  settings.columnOrder.forEach((columnName) => {
    const column = columnDefinitions.get(columnName);
    if (column) {
      columns.push(column);
    }
  });
  return [columns, visibility];
}
