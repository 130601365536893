import Stack from "@mui/material/Stack";
import React from "react";

import DictionaryTable from "./Dictionary/DictionaryTable";
import DictionaryToolbar from "./Dictionary/DictionaryToolbar";

function DictionaryScreen() {
  return (
    <React.Fragment>
      <Stack spacing={2} alignContent="center" style={{ height: "80vh" }}>
        <DictionaryToolbar />
        <DictionaryTable />
      </Stack>
    </React.Fragment>
  );
}

export default DictionaryScreen;
