import { ApiResponse, WordModel } from "@conworkshop/api-js";
import { useMutation, UseMutationResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type PostWordResponse = ApiResponse<WordModel>;

function useApiCreateDictionaryWord(): UseMutationResult<
  PostWordResponse,
  unknown,
  WordModel
> {
  const apiClient = useApiClient();
  return useMutation(async (word: WordModel) => {
    const code = word.language.code;
    return apiClient.createDictionaryWord(code, {
      word: word.word,
      partOfSpeech: word.partOfSpeech?.code,
      wordlink: word.wordlink?.id,
      classes: word.classes?.map((c) => c.code),
      alternateWord: word.alternateWord,
      etymology: word.etymology,
      imageUrl: word.imageUrl,
      ipa: word.ipa,
      notes: word.notes,
      register: word.register?.code,
      sample: word.sample,
      sourceLanguage: word.sourceLanguage,
      xsampa: word.xsampa,
    });
  });
}

export default useApiCreateDictionaryWord;
