import {
  ApiDictionaryWordSortFacet,
  ApiSortDir,
  WordFacet,
} from "@conworkshop/api-js";

export type DictionaryQuery = {
  search?: string;
  pageNumber: number;
  pageSize: number;
  wordFacets: WordFacet[];
  filterValue?: string;
  sortFacet?: ApiDictionaryWordSortFacet;
  sortDir?: ApiSortDir;
};

export enum DictionaryColumnName {
  WORD = "word",
  WORD_LINK = "wordlink",
  PART_OF_SPEECH = "partOfSpeech",
  ALTERNATE_WORD = "alternateWord",
  CLASSES = "classes",
  CREATED_AT = "createdAt",
  DIALECT = "dialect",
  ETYMOLOGY = "etymology",
  IMAGE_URL = "imageUrl",
  IPA = "ipa",
  NOTES = "notes",
  REGISTER = "register",
  SAMPLE = "sample",
  SOURCE_LANGUAGE = "sourceLanguage",
  XSAMPA = "xsampa",
}

export type DictionaryColumnWidthSettings = Record<
  DictionaryColumnName,
  number
>;

export type DictionarySettings = {
  columnOrder: DictionaryColumnName[];
  columnWidths: DictionaryColumnWidthSettings;
};
