import React from "react";
import { Route, Switch, useParams } from "react-router-dom";

import { LanguageContextProvider } from "../../../Contexts/LanguageContext";
import ROUTES from "../../../router";
import DictionaryContextProvider from "./Dictionary/DictionaryContext";
import DictionaryScreen from "./DictionaryScreen";
import LanguageMenu from "./LanguageMenu";
import ViewWordScreen from "./ViewWordScreen";

function LanguageRouter() {
  const { code } = useParams<{ code: string }>();
  return (
    <LanguageContextProvider code={code}>
      <LanguageMenu />
      <Switch>
        <Route path={ROUTES.LANGUAGES.LANGUAGE.DICTIONARY.WORD_VIEW.route}>
          <ViewWordScreen />
        </Route>
        <Route path={ROUTES.LANGUAGES.LANGUAGE.DICTIONARY.route}>
          <DictionaryContextProvider>
            <DictionaryScreen />
          </DictionaryContextProvider>
        </Route>
        <Route path={ROUTES.LANGUAGES.LANGUAGE.route}>
          <DictionaryContextProvider>
            <DictionaryScreen />
          </DictionaryContextProvider>
        </Route>
      </Switch>
    </LanguageContextProvider>
  );
}

export default LanguageRouter;
