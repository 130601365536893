import { ApiResponse, LanguageDialectReference } from "@conworkshop/api-js";
import { useQuery, UseQueryResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type ApiWordsListParams = {
  code: string;
};

export type GetLanguageDialectsResponse = ApiResponse<
  LanguageDialectReference[]
>;

function useApiListLanguageDialects(
  params: ApiWordsListParams
): UseQueryResult<GetLanguageDialectsResponse> {
  const apiClient = useApiClient();
  const { code } = params;
  return useQuery(["languages.dialects", { params }], async () => {
    return apiClient.listLanguageDialects(code);
  });
}

export default useApiListLanguageDialects;
