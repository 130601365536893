import { UserModel } from "@conworkshop/api-js";
import React, { createContext, useContext, useState } from "react";

import useApiRetrieveCurrentUser from "../Api/useApiRetrieveCurrentUser";
import QueryResultRenderer from "../Components/QueryHandling/QueryResultRenderer";
import useQuerySuccess from "../Components/QueryHandling/useQuerySuccess";

export type UserContextState = {
  user: UserModel | undefined;
};

export type UserContextProviderProps = {
  children: (() => React.ReactNode) | React.ReactNode;
};

const UserContext = createContext<UserContextState | undefined>(undefined);

function UserContextProvider({ children }: UserContextProviderProps) {
  const query = useApiRetrieveCurrentUser();
  const [isStateReady, setIsStateReady] = useState(false);
  const [user, setUser] = useState<UserModel | undefined>();

  useQuerySuccess(query, (res) => {
    setUser(res.data);
    setIsStateReady(true);
  });

  const state = {
    user,
  };

  return (
    <QueryResultRenderer query={query} isStateReady={isStateReady}>
      {() => (
        <UserContext.Provider value={state}>
          {typeof children === "function" ? children() : children}
        </UserContext.Provider>
      )}
    </QueryResultRenderer>
  );
}

function useUserContext(): UserContextState {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("UserContext is empty.");
  }
  return context;
}

export { UserContextProvider, useUserContext };
