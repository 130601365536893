import {
  ApiCursorResponse,
  ApiDictionaryWordFilterFacet,
  ApiDictionaryWordSortFacet,
  ApiSortDir,
  WordModel,
} from "@conworkshop/api-js";
import { useQuery, UseQueryResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type ApiWordsQueryParams = {
  page: number;
  limit: number;
  code: string;
  search?: string;
  filterValue?: string;
  sortFacet?: ApiDictionaryWordSortFacet;
  sortDir?: ApiSortDir;
};

export type GetDictionaryWordsResponse = ApiCursorResponse<WordModel[]>;

function useApiQueryDictionaryWords(
  params: ApiWordsQueryParams
): UseQueryResult<GetDictionaryWordsResponse> {
  const apiClient = useApiClient();
  const { code, search, page, limit } = params;
  return useQuery(
    [`words.query.${code}`, { params }],
    async () => {
      const filter = params.filterValue
        ? {
            filterFacet: ApiDictionaryWordFilterFacet.PART_OF_SPEECH,
            filterValue: params.filterValue,
          }
        : {};
      return apiClient.queryDictionaryWords(code, search, {
        page,
        limit,
        sortFacet: params.sortFacet,
        sortDir: params.sortDir,
        ...filter,
      });
    },
    { keepPreviousData: false }
  );
}

export default useApiQueryDictionaryWords;
