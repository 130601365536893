import Breadcrumbs from "@mui/material/Breadcrumbs";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useParams } from "react-router-dom";

import Link from "../../../Components/Typography/Link";
import { useLanguageContext } from "../../../Contexts/LanguageContext";
import ROUTES from "../../../router";

const useStyles = makeStyles({
  root: {
    marginTop: 24,
    marginBottom: 24,
  },
});

function LanguageMenu() {
  const { code } = useParams<{ code: string }>();
  const classes = useStyles();
  const { language } = useLanguageContext();
  const { flagSmall, name } = language;

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
      <div>
        <img src={flagSmall} alt={name} /> <b>{name}</b>
      </div>

      <Link to={ROUTES.ROOT.route}>Languages</Link>

      <Link to={ROUTES.LANGUAGES.LANGUAGE.DICTIONARY.create({ code })}>
        Dictionary
      </Link>
    </Breadcrumbs>
  );
}

export default LanguageMenu;
