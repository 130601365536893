import { ApiResponse, UserModel } from "@conworkshop/api-js";
import { useQuery, UseQueryResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type GetUserMeResponse = ApiResponse<UserModel>;

function useApiRetrieveCurrentUser(): UseQueryResult<GetUserMeResponse> {
  const apiClient = useApiClient();
  return useQuery("user.current", async () => {
    return apiClient.retrieveCurrentUser();
  });
}

export default useApiRetrieveCurrentUser;
