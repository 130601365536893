import { ApiClient } from "@conworkshop/api-js";
import { ConWorkshopSDK } from "@conworkshop/sdk-js";
import React, { createContext, useContext, useEffect, useState } from "react";

import CONFIG from "../config";

export type ApiContextState = {
  apiClient?: ApiClient;
  cwsSdk?: ConWorkshopSDK;
};

export type ApiContextProps = {
  children: (() => React.ReactElement) | React.ReactElement;
};

const ApiContext = createContext<ApiContextState | undefined>(undefined);

function ApiContextProvider(props: ApiContextProps) {
  const { children } = props;
  const [apiClient, setApiClient] = useState<ApiClient | undefined>();
  const [cwsSdk, setCwsSdk] = useState<ConWorkshopSDK | undefined>();

  useEffect(() => {
    const apiClient = new ApiClient({ isSessionAccess: true, baseUrl: CONFIG.apiBaseUrl })
    const cwsSdk = new ConWorkshopSDK({ hasApiClient: true, apiClient })
    setApiClient(
      apiClient
    );
    setCwsSdk(
      cwsSdk
    );
  }, []);

  const state = {
    apiClient,
    cwsSdk,
  };
  return (
    <ApiContext.Provider value={state}>
      {apiClient
        ? typeof children === "function"
          ? children()
          : children
        : null}
    </ApiContext.Provider>
  );
}

export function useApiClient(): ApiClient {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("ApiContext is empty.");
  }
  if (!context.apiClient) {
    throw new Error("ApiClient is not initialized.");
  }
  return context.apiClient;
}

export function useConWorkshopSdk(): ConWorkshopSDK {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("ApiContext is empty.");
  }
  if (!context.cwsSdk) {
    throw new Error("ConWorkshopSDK is not initialized.");
  }
  return context.cwsSdk;
}

export default ApiContextProvider;
