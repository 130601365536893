import { Theme } from "@mui/material";
import MuTypography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

import {
  ComponentDisplay,
  ComponentFontColor,
  ComponentFontWeight,
  ComponentSize,
  toFontColor,
  toFontSize,
  toFontWeight,
} from "../styles";

export type TextProps = {
  size?: ComponentSize;
  fontWeight?: ComponentFontWeight;
  fontColor?: ComponentFontColor;
  display?: ComponentDisplay;
  children: (string | undefined) | string[] | undefined;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: TextProps) => ({
    fontSize: toFontSize(props),
    fontWeight: toFontWeight(props),
    color: toFontColor(props, theme),
  }),
}));

function Text(props: TextProps) {
  const { children, display } = props;
  const classes = useStyles(props);

  return (
    <MuTypography
      className={classes.root}
      display={display === "inline" ? "inline" : "block"}
    >
      {children}
    </MuTypography>
  );
}

export default Text;
