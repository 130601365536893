import { ApiResponse, DictionarySettingsModel } from "@conworkshop/api-js";
import { useQuery, UseQueryResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type ApiWordsListParams = {
  code: string;
};

export type GetDictionarySettingsResponse =
  ApiResponse<DictionarySettingsModel>;

function useApiRetrieveDictionarySettings(
  params: ApiWordsListParams
): UseQueryResult<GetDictionarySettingsResponse> {
  const apiClient = useApiClient();
  const { code } = params;
  return useQuery(["languages.settings", { params }], async () => {
    return apiClient.retrieveDictionarySettings(code);
  });
}

export default useApiRetrieveDictionarySettings;
