import { Theme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MuTextField, {
  TextFieldProps as MuTextFieldProps,
} from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import React from "react";

import { ComponentProps, toFontSize, toWidthProperty } from "../styles";

export type SelectProps<O = any> = MuTextFieldProps &
  ComponentProps & {
    value: O | undefined;
    options: O[];
    allowEmpty: boolean;
    error?: boolean;
    onBlur?: () => void;
    onChangeValue: (value: O | undefined) => void;
    getOptionLabel: (option: O) => string;
    getOptionValue: (option: O) => string;
  };

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: SelectProps) => ({
    fontSize: toFontSize(props),
    width: toWidthProperty(props, 200),
  }),
  select: (props: SelectProps) => ({
    fontSize: toFontSize(props),
  }),
  selectableOption: (props: SelectProps) => ({
    fontSize: toFontSize(props),
  }),
  unselectedOption: (props: SelectProps) => ({
    fontSize: toFontSize(props),
    color: theme.palette.text.disabled,
  }),
}));

function Select<O = any>(props: SelectProps<O>) {
  const {
    label,
    placeholder,
    value,
    allowEmpty,
    options,
    getOptionLabel,
    getOptionValue,
    onBlur,
    onChangeValue,
    ...other
  } = props;
  const classes = useStyles(props);

  const onBlurHandler = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (onBlur) {
      onBlur();
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<{ value: string }>) => {
    event.preventDefault();
    const option = options.find(
      (o) => getOptionValue(o) === event.target.value
    );
    onChangeValue(option);
  };

  const renderEmpty = () => {
    if (allowEmpty) {
      return (
        <MenuItem className={classes.unselectedOption} value="">
          {placeholder ?? ""}
        </MenuItem>
      );
    }
  };
  return (
    <MuTextField
      className={classes.root}
      inputProps={{
        className: classes.select,
      }}
      select
      label={label}
      placeholder=""
      value={value ? getOptionValue(value) : ""}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      {...other}
    >
      {renderEmpty()}
      {options.map((option) => (
        <MenuItem
          key={getOptionValue(option)}
          className={classes.selectableOption}
          value={getOptionValue(option)}
        >
          {getOptionLabel(option)}
        </MenuItem>
      ))}
    </MuTextField>
  );
}

export default Select;
