import { Grid } from "@mui/material";
import React from "react";

export type FieldGridProps = {
  children: React.ReactElement | React.ReactElement[];
};

function FieldGrid(props: FieldGridProps) {
  const { children } = props;
  return (
    <Grid container spacing={4}>
      {children}
    </Grid>
  );
}

export default FieldGrid;
