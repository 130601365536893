import { Theme } from "@mui/material";
import MuiLink from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import React from "react";
import {
  Link as RouteLink,
  LinkProps as RoutLinkProps,
} from "react-router-dom";

import {
  ComponentFontWeight,
  ComponentSize,
  toFontSize,
  toFontWeight,
} from "../styles";

export type LinkProps = RoutLinkProps & {
  size?: ComponentSize;
  fontWeight?: ComponentFontWeight;
  children: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: LinkProps) => ({
    fontSize: toFontSize(props),
    fontWeight: toFontWeight(props),
    color: theme.palette.primary.dark,
    textDecoration: "none",
    cursor: "pointer",
  }),
}));

function Link(props: LinkProps) {
  const { to, children } = props;
  const classes = useStyles(props);

  return (
    <MuiLink component={RouteLink} to={to} className={classes.root}>
      {children}
    </MuiLink>
  );
}

export default Link;
