import { ApiResponse, WordClassModel } from "@conworkshop/api-js";
import { useQuery, UseQueryResult } from "react-query";

import { useApiClient } from "./ApiContext";

export type ApiWordsListParams = {
  code: string;
};

export type GetDictionaryClassesResponse = ApiResponse<WordClassModel[]>;

function useApiListDictionaryClasses(
  params: ApiWordsListParams
): UseQueryResult<GetDictionaryClassesResponse> {
  const apiClient = useApiClient();
  const { code } = params;
  return useQuery(["languages.classes", { params }], async () => {
    return apiClient.listDictionaryClasses(code);
  });
}

export default useApiListDictionaryClasses;
