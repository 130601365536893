import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React from "react";

import useApiListCurrentUserLanguages from "../../Api/useApiListCurrentUserLanguages";
import QueryResultRenderer from "../../Components/QueryHandling/QueryResultRenderer";
import H2 from "../../Components/Typography/H2";
import Link from "../../Components/Typography/Link";
import ROUTES from "../../router";

function LanguageHomeScreen() {
  const query = useApiListCurrentUserLanguages();

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}></Grid>
        <Grid item md={4} xs={12}>
          <H2>My Languages</H2>
          <QueryResultRenderer query={query}>
            {(res) => (
              <List>
                {res.data.map((language) => (
                  <ListItem key={language.code}>
                    <img src={language.flagSmall} alt="" />
                    &nbsp;
                    <Link
                      to={ROUTES.LANGUAGES.LANGUAGE.create({
                        code: language.code,
                      })}
                    >
                      {language.name}
                    </Link>
                  </ListItem>
                ))}
              </List>
            )}
          </QueryResultRenderer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default LanguageHomeScreen;
