import MuChip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import React from "react";

import { ComponentSize, toFontSize } from "../styles";

export type ChipProps = {
  label: string;
  size?: ComponentSize;
  variant?: "outlined" | "filled";
  color?: "default" | "primary" | "secondary";
  onClick?: () => {};
  onDelete?: () => {};
};

const useStyles = makeStyles({
  root: (props: ChipProps) => ({
    fontSize: toFontSize(props) - 2,
    marginRight: 6,
    marginBottom: 6,
  }),
});

function Chip(props: ChipProps) {
  const { label, variant, color, onClick, onDelete } = props;
  const classes = useStyles(props);

  return (
    <MuChip
      className={classes.root}
      label={label}
      variant={variant}
      color={color}
      onClick={onClick}
      onDelete={onDelete}
    />
  );
}

export default Chip;
