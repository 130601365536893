export function canEditLanguage(
  code: string,
  access: Record<string, string>
): boolean {
  if (!access) {
    return false;
  }
  const permission = access[`language:${code}`] ?? "";
  return permission.indexOf("write") > -1;
}
