import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import React from "react";

import { ComponentFontWeight, ComponentSize } from "../styles";
import Text from "../Typography/Text";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("en");

export type DateFormatProps = {
  size?: ComponentSize;
  fontWeight?: ComponentFontWeight;
  date?: number;
};

function DateFormat(props: DateFormatProps) {
  const { date, ...other } = props;
  return (
    <Text {...other}>{date ? timeAgo.format(date * 1000).toString() : ""}</Text>
  );
}

export default DateFormat;
