import { ModelType, WordPartOfSpeech } from "@conworkshop/api-js";

export const POS_V: WordPartOfSpeech = {
  code: "V",
  type: ModelType.PART_OF_SPEECH,
  label: "Verb",
};

export const POS_ADJ: WordPartOfSpeech = {
  code: "ADJ",
  type: ModelType.PART_OF_SPEECH,
  label: "Adjective",
};

export const POS_N: WordPartOfSpeech = {
  code: "N",
  type: ModelType.PART_OF_SPEECH,
  label: "Noun",
};

export const POS_C: WordPartOfSpeech = {
  code: "C",
  type: ModelType.PART_OF_SPEECH,
  label: "Conjunction",
};

export const POS_P: WordPartOfSpeech = {
  code: "P",
  type: ModelType.PART_OF_SPEECH,
  label: "Pronoun",
};

export const POS_I: WordPartOfSpeech = {
  code: "I",
  type: ModelType.PART_OF_SPEECH,
  label: "Interjection",
};

export const POS_ADV: WordPartOfSpeech = {
  code: "ADV",
  type: ModelType.PART_OF_SPEECH,
  label: "Adverb",
};

export const POS_PHR: WordPartOfSpeech = {
  code: "PHR",
  type: ModelType.PART_OF_SPEECH,
  label: "Phrase",
};

export const POS_AFF: WordPartOfSpeech = {
  code: "AFF",
  type: ModelType.PART_OF_SPEECH,
  label: "Affix",
};

export const POS_PRT: WordPartOfSpeech = {
  code: "PRT",
  type: ModelType.PART_OF_SPEECH,
  label: "Particle",
};

export const POS_ADP: WordPartOfSpeech = {
  code: "ADP",
  type: ModelType.PART_OF_SPEECH,
  label: "Adposition",
};

export const POS_AUX: WordPartOfSpeech = {
  code: "AUX",
  type: ModelType.PART_OF_SPEECH,
  label: "Auxilliary",
};

export const POS_PPR: WordPartOfSpeech = {
  code: "PPR",
  type: ModelType.PART_OF_SPEECH,
  label: "Proper Noun",
};

export const POS_ABB: WordPartOfSpeech = {
  code: "ABB",
  type: ModelType.PART_OF_SPEECH,
  label: "Abbreviation",
};

export const POS_DET: WordPartOfSpeech = {
  code: "DET",
  type: ModelType.PART_OF_SPEECH,
  label: "Determiner",
};

export const POS_NUM: WordPartOfSpeech = {
  code: "NUM",
  type: ModelType.PART_OF_SPEECH,
  label: "Numeral",
};

export const POS_LIST: WordPartOfSpeech[] = [
  POS_V,
  POS_ADJ,
  POS_N,
  POS_C,
  POS_P,
  POS_I,
  POS_ADV,
  POS_PHR,
  POS_AFF,
  POS_PRT,
  POS_ADP,
  POS_AUX,
  POS_PPR,
  POS_ABB,
  POS_DET,
  POS_NUM,
].sort((a, b) => a.label.localeCompare(b.label));

export function getPartOfSpeech(
  code: string | undefined,
  isRequired = false
): WordPartOfSpeech | undefined {
  const pos = POS_LIST.find((p) => p.code === code);
  if (!pos && isRequired) {
    throw new Error("Unexpected value for Part Of Speech.");
  }
  return pos;
}
