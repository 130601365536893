import React from "react";
import { UseQueryResult } from "react-query";

export interface QueryResultRendererProps<T> {
  query: UseQueryResult<T>;
  isStateReady?: boolean;
  renderLoading?: React.ReactElement;
  children: ((data: T) => React.ReactElement) | React.ReactElement;
}

function QueryResultRenderer<T = {}>(props: QueryResultRendererProps<T>) {
  const { query, renderLoading, children } = props;

  const isStateReady = props.isStateReady === undefined || props.isStateReady;

  if (query.isError) {
    return <div>{(query.error as any).response.data.message}</div>;
  }

  if (isStateReady && query.isFetched) {
    if (query.data !== undefined) {
      return typeof children === "function" ? children(query.data) : children;
    }
    throw new Error("Data result was undefined.");
  }
  return renderLoading || <React.Fragment />;
}

export default QueryResultRenderer;
